module.exports = [{
      plugin: require('/Users/benoror/code/benoror.github.io/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/benoror/code/benoror.github.io/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-76368021-1"},
    },{
      plugin: require('/Users/benoror/code/benoror.github.io/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
